<template>
  <Template
			v-bind="params" :is-loading="isLoading"
			:formated_address="formated_address"
			:places="state.google.placeResults"
			:site="site"
			@onSubmit="(list) => onSubmit(list)"
			@onChangeAddress="(address) => searchGooglePlaces(address)"
			@onClickAddress="item => onClickAddress(item)"
	/>
</template>

<script>
import Template from '../components/templates/DemographicInformation/DemographicInformation'
import {c} from '../components/constants'
export default {
	components: {
		Template
	},
	data() {
		return {
			params: c.demographics,
			isLoading: false,
			site: null,
			formated_address: null
		}
	},
	methods: {
		onClickAddress(site) {
			this.site = site
			this.formated_address = site.formatted_address;
			console.log(this.site, this.formated_address, '===============')
			setTimeout(() => this.actions.google.emptyPlaceResults(), 1500)
		},
		async searchGooglePlaces(val) {
			try {
				if (val) {
					await this.actions.google.searchGooglePlaces({keyword: val});
				}
			} catch (e) {
				console.log(e)
			}
		},
		async onSubmit(list) {
			try {
				this.isLoading = true;
				await this.actions.user.updateUser({
					employer: {
						details: this.site,
					}
				})
				const params = {
					where: {id: this.state.currentUser?.id},
					data: {
						ageGroup: {
							create: {}
						},
						householdIncomeRange: {
							create: {}
						},
						races: {
							create: []
						}
					}
				}
				// AGE
				const ageValue = list[0].value;
				if (ageValue === '75+') {
					params.data.ageGroup.create = {lowerLimit: 75, upperLimit: 0}
				} else if (ageValue === 'Prefer not to say') {
					params.data.ageGroup.create = {lowerLimit: 0, upperLimit: 0}
				} else {
					const items = ageValue.split('-');
					params.data.ageGroup.create = {lowerLimit: parseInt(items[0]), upperLimit: parseInt(items[1])}
				}

				// GENDER
				const genderValue = list[1].value;
				if (genderValue === 'Prefer not to say') {
					params.data.gender = 'NO_ANSWER'
				} else if (genderValue === 'Self describe') {
					params.data.genderDescribed = list[1].inputValue;
					params.data.gender = 'OTHER'
				} else {
					params.data.gender = genderValue.toUpperCase()
				}

				// RACE
				const raceValue = list[2].value;
				raceValue.map(r => {
					if (r === 'Self describe') {
						params.data.races.create.push({name: list[2].inputValue, label: list[2].inputValue})
					} else {
						params.data.races.create.push({name: r, label: r})
					}
				})

				// INCOME
				const incomeValue = list[4].value;
				if (incomeValue.includes('Lower')) {
					params.data.householdIncomeRange.create = {lowerLimit: 0, upperLimit: 50}
				} else if (incomeValue.includes('Middle')) {
					params.data.householdIncomeRange.create = {lowerLimit: 50, upperLimit: 150}
				} else if (incomeValue.includes('Higher')) {
					params.data.householdIncomeRange.create = {lowerLimit: 150, upperLimit: 0}
				} else {
					params.data.householdIncomeRange.create = {lowerLimit: 0, upperLimit: 0}
				}

				await this.actions.user.saveUser(params);
				this.actions.alert.showSuccess({message: 'Updated demographic information successfully!'});
				this.$router.push('/interest-activity')
			} catch (e) {
				console.log(e, 'error')
			} finally {
				this.isLoading = false;
			}
		}
	}
}
</script>
