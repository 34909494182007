<template>
	<div class="grid w-full min-h-screen grid-cols-1 md:grid-cols-5">
		<div class="flex flex-col h-screen col-span-1 md:col-span-2 overflow-hidden">
			<div class=" h-full overflow-y-scroll pt-10 sm:pt-20 px-3 sm:px-0 ">
				<div class="max-w-md px-2 mx-auto">
					<img src="../../../assets/images/dscvry_logo-grey.svg" class="h-6"/>
					<Text content="Tell us about yourself" customClass="mt-8 tracking-tight" size="3xl" color="gray-800"/>
				</div>
				<div class="flex flex-col h-full mt-4 px-2 mx-auto max-w-md">
					<Text
							content="DSCVRY is all about learning about the real experiences of different groups of people, and this information will tell us a little about what groups you might be a part of."
							color="gray-800"/>
					<div class="space-y-7 mt-5 w-full pb-20">
						<div v-for="(item, id) in list" :key="id">
							<Text size="" weight="semibold" color="black" :content="item.label"/>
<!--							<Text v-if="item.error" size="xs" weight="medium" color="rose-600" :content="item.error"/>-->
							<RadioGroup v-if="item.type === 'RADIO'" :items="item.options" is-simple-list v-model:value="item.value" :name="slugify(item.label)"/>
							<CheckboxGroup v-if="item.type === 'CHECKBOX'" :items="item.options" class="mt-5"
														 v-model:selected="item.value"/>
<!--							<AutoComplete v-if="item.type === 'AUTOCOMPLETE'" :placeholder="item.placeholder"-->
<!--														:handle-select-fn="d => item.value = d" :listData="item.options || []" class="mt-5"/>-->
							<div class="relative" v-if="item.type === 'AUTOCOMPLETE'">
								<Input label="address" v-model:value="address"/>
								<div class='absolute bg-white mt-4 top-12 left-0 overflow-y-scroll w-full z-10' v-if='isOpenList' :class="places.length === 0 ? 'border-none' : 'border'"
										 style="max-height: 200px;">
									<a class='border-b py-3 px-4 tracking-tight text-xs flex items-center w-full hover:text-yellow'
										 v-for='(place, index) in places' :key='index'
										 @click='() => {isOpenList = false; $emit("onClickAddress", places[index])}'>{{ place.formatted_address }}</a>
								</div>
							</div>
							<Input v-model:value="item.inputValue"
										 v-if="item.value === 'Self describe' || (isArray(item.value) && item.value.find(v => v === 'Self describe'))"
										 placeholder="Describe how you identify yourself?" custom-class="mt-4"/>
						</div>
					</div>
				</div>
			</div>
			<div class="flex-none">
				<div>
					<div class="fixed bottom-0 xs:relative sm:relative w-full py-3 border-t bg-gray-50 px-3 sm:px-0 ">
						<div class="flex justify-end max-w-md px-2 mx-auto">
							<Button :content="'Next'" :disabled="onValidate()" variant="primary" :onClick="() => onNext()" :is-loading="isLoading"/>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="hidden h-full col-span-1 md:col-span-3 md:block">
			<Image :src="image.src" :alt="image.alt" width="full" height="screen" customClass="object-cover"/>
		</div>
	</div>
</template>

<script>
/**
 - Combobox with text input to filter onh    basis of name property on default
 - give filter key to provide filter on a custom property
 **/
import Text from '../../atoms/Text/Text.vue';
import Link from '../../atoms/Link/Link.vue';
import Checkbox from '../../molecules/Inputs/Checkbox/Checkbox.vue';
import Image from '../../atoms/Image/Image.vue';
import Input from '../../molecules/Inputs/Component/Component.vue';
import Button from '../../atoms/Button/Button.vue';
import RadioGroup from '../../molecules/RadioGroup/Simple/Simple'
import CheckboxGroup from '../../molecules/Checkboxes/SimpleList/SimpleList'
import AutoComplete from "../../molecules/AutoComplete/AutoComplete";
import {isArray} from 'lodash'
import slugify from 'slugify'

export default {
	components: {
		AutoComplete,
		Text,
		Link,
		Checkbox,
		Image,
		Input,
		Button,
		RadioGroup,
		CheckboxGroup
	},
	props: {
		image: {
			type: Object,
			default: () => {
			}
		},
		items: {
			type: Array,
			default: () => []
		},
		button: {
			type: Object,
			default: () => {
			}
		},
		isLoading: {
			type: Boolean,
			default: false,
		},
		formated_address: {
			type: String,
			default: ''
		},
		places: {
			type: Array,
			default: () => []
		},
		site: {
			type: Object,
			default: () => {}
		}

	},
	watch: {
		list: {
			handler(val) {
				console.log(val)
			},
			deep: true
		},
		address: function (val) {
			this.isOpenList = val;
			this.$emit('onChangeAddress', val)
		},
		formated_address: function (val) {
			console.log(val, 'val')
			this.address = val;
			this.isOpenList = false;
		}
	},
	data() {
		return {
			list: [...this.items],
			isArray,
			slugify,
			address: null,
			isOpenList: false,
		}
	},
	methods: {
		setPlace(e) {
			console.log(e)
		},
		onNext() {
			this.$emit('onSubmit', this.list)
		},
		onValidate() {
			this.list.map(l => {
				if (!l.value) {
					l.error = 'Please select at least an option!'
				} else if (l.value && (l.value === 'Self describe' || (isArray(l.value) && l.value?.find(v => v === 'Self describe')) && !l.inputValue)) {
					l.error = 'Please describe your identity!';
				} else {
					l.error = null
				}
			})
			console.log(this.list, 'list')
			const items = this.list.filter(l => !l.error);
			if (items.length === 4 && this.site?.formatted_address === this.address) {
				return false
			} else {
				return true
			}
		}
	},
	updated() {
		console.log(this.items, 'items')
	}
};
</script>
